<template>
  <form name="form" @submit.prevent="signIn">
    <div class="form-group">
      <label for="username">E-post<span class="text-danger" aria-hidden="true">*</span></label>
      <input type="email" name="username" v-model="username" id="username" class="form-control" required>
    </div>

    <div class="form-group">
      <label for="password">Lösenord<span class="text-danger" aria-hidden="true">*</span></label>
      <input type="password" name="password" v-model="password" id="password" class="form-control" required>
    </div>

    <button type="submit" class="btn btn-primary">Logga in</button>
  </form>
</template>

<script>
  import { firebase } from "@/initializers/firebase"

  export default {
    name: "auth-form",
    props: {
      callback: {
        type: Function,
        default: () => { window.location.reload() }
      }
    },
    data() {
      return {
        username: null,
        password: null,
        error: null
      }
    },
    methods: {
      async signIn() {
        try {
          await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
            return firebase.auth().signInWithEmailAndPassword(this.username, this.password)
          }).catch((error) => {
            throw error
          })

          this.callback()
        } catch(error) {
          this.error = error
        }
      }
    }
  }
</script>