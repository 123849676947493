<template>
  <div class="auth d-flex flex-column h-100 w-100 align-items-center justify-content-center">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="card rounded">
            <div class="card-body">
              <div class="row align-items-center mb-3">
                <div class="col text-center">
                  <img src="../../assets/tornhuset.png" class="logo" alt="Agnes">
                  <span class="d-block font-weight-bold mt-2">BRF TORNHUSET</span>
                </div>
              </div>

              <auth-form :callback="signInCallback"></auth-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AuthForm from "@/auth-form.vue"

  export default {
    name: "auth",
    components: { AuthForm },
    methods: {
      signInCallback() {
        this.$router.replace({ name: "home" })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .auth {
    background-color: #8bacbd;
  }

  .logo {
    max-width: 60px;
  }
</style>